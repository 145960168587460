.section.section-testimonials {
  display: flex;
  justify-content: center;
}
.section.section-testimonials hr {
  display: none;
}
.section.section-testimonials p {
  padding: 0 25px;
  text-align: center;
  font-weight: 300;
  line-height: 1.3;
}
.section.section-testimonials .testimonial {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  padding: 15px 0;
}
.section.section-testimonials .profile-picture {
  width: 100px;
  border-radius: 50%;
}
.section.section-testimonials .person {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px 0 25px;
}
.section.section-testimonials .person .name {
  padding-left: 15px;
  font-size: 0.8rem;
  color: #24283B;
}
@media(max-width: 936px) {
  .section.section-testimonials p {
    padding: 0 15px;
    line-height: 1.2;
  }
  .section.section-testimonials .testimonial {
    padding: 0;
  }
  .section.section-testimonials .profile-picture {
    width: 70px;
  }
  .section.section-testimonials .person .name {
    padding-left: 10px;
    font-size: 0.75rem;
  }
}
@media(max-width: 656px) {
  .section.section-testimonials {
    flex-direction: column;
  }
  .section.section-testimonials hr {
    display: block;
    margin: 15px 30px;
  }
  .section.section-testimonials .testimonial {
    width: 100%;
  }
}
