.navigation .items {
  display: flex;
  justify-content: flex-end;
}
.navigation .items .item {
  margin: 0 30px;
  font-size: 0.9rem;
  font-weight: 600;
}
.navigation a {
  text-decoration: none;
  color: #24283B;
  padding-bottom: 2px;
  border-bottom: 2px solid transparent;
}
.navigation a:hover {
  padding-bottom: 2px;
  border-bottom: 2px solid rgba(30, 34, 51, 0.2);
}
@media(max-width: 936px) {
  .navigation .items {
    justify-content: center;
  }
  .navigation .items .item {
    margin: 0 15px;
  }
}
@media(max-width: 656px) {
  .navigation .items .item {
    margin: 0 12px;
    font-size: 0.75rem;
  }
}
